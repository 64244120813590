import { ReactNode, useState } from "react";

import { useLocation } from "react-router-dom";

import { useIsSmallScreen } from "../context/ScreenSizeContext";
import PlatformSidebar from "../global/PlatformSidebar";

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const isSmallScreen = useIsSmallScreen();
    const location = useLocation();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
    const [isHidden, setIsHidden] = useState(true);

    const isLandingRoute = location.pathname === "/";

    return (
        <div id="app" className="relative flex min-h-screen w-full flex-col">
            <div className="flex h-full flex-grow">
                {!isLandingRoute && (
                    <PlatformSidebar
                        isSmallScreen={isSmallScreen}
                        isCollapsed={isSidebarCollapsed}
                        setIsCollapsed={setIsSidebarCollapsed}
                        isHidden={isHidden}
                        setIsHidden={setIsHidden}
                    />
                )}
                <div className="h-screen w-full py-4">{children}</div>
            </div>
        </div>
    );
};

export default Layout;
