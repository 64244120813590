import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";

import app from "../auth/firebase";
import { fetch_with_auth, setCurrentUrlParam } from "../components/Util";
import { tokens } from "../theme";
import { Fn } from "../types/Fn";
import { Space } from "../types/Space";
import { UserProfile } from "../types/UserProfile";

const SpaceEditorPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    const [space, setSpace] = useState<Space>(
        new Space({
            name: "",
            description: "",
            is_public: false,
            author_id: "",
            eligible_user_emails: [],
            eligible_teams: [],
            functions: {},
            tables: {},
        }),
    );
    const [fns, setFns] = useState<Fn[]>([]);

    const handleSave = async () => {
        toast.loading("Saving Space...");
        try {
            space.author_id = userProfile.user_id;
            space.author_name = userProfile.display_name || "Anonymous";
            space.space_id = space.space_id || "";

            const res = await space.save_to_spanner(userProfile.id_token || "");
            console.log(res);
            const { space_id } = res;
            space.update(setSpace, { ...space, space_id });
            toast.dismiss();
            toast.success("Saved Space successfully.");
            setCurrentUrlParam("space_id", space_id);
        } catch (error: any) {
            toast.dismiss();
            toast.error(`Failed to save item: ${error.message}`);
        }
    };

    const fetchSpaceIfNeeded = async (id_token: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const spaceId = searchParams.get("space_id");
        if (!spaceId) {
            return;
        }

        try {
            const data = await Space.loadSpace(spaceId, id_token);
            setSpace(new Space(data));
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const fetchFns = async (id_token: string) => {
        if (id_token && id_token.trim() !== "") {
            const data = await fetch_with_auth(`functions`, id_token, "GET");
            return data;
        } else {
            return null;
        }
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchSpaceIfNeeded(userProfile.id_token);
            fetchFns(userProfile.id_token).then((data) => setFns(data));
        }
    }, [userProfile.id_token]);

    const handleAddAction = () => {
        const newSpace = new Space({
            ...space,
            functions: { ...space.functions, "": "" },
        });
        setSpace(newSpace);
    };

    const handleRemoveAction = (actionName: string) => {
        const { [actionName]: value, ...remainingActions } = space.functions;
        const newSpace = new Space({
            ...space,
            functions: remainingActions,
        });
        setSpace(newSpace);
    };

    const handleActionNameChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        oldActionName: string,
    ) => {
        const newActionName = e.target.value;
        const newActions = { ...space.functions };
        newActions[newActionName] = newActions[oldActionName];
        delete newActions[oldActionName];

        setSpace(
            new Space({
                ...space,
                functions: newActions,
            }),
        );
    };

    return (
        <Container className="min-w-full">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        value={space.name || ""}
                        onChange={(e) =>
                            setSpace(
                                new Space({
                                    ...space,
                                    name: e.target.value,
                                }),
                            )
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        label="Description"
                        value={space.description || ""}
                        onChange={(e) =>
                            setSpace(new Space({ ...space, description: e.target.value }))
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Visibility</Typography>
                    <Divider variant="middle" />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={space.is_public || false}
                                onChange={(e) =>
                                    setSpace(new Space({ ...space, is_public: e.target.checked }))
                                }
                            />
                        }
                        label="Public"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="emails"
                        label="Eligible User Emails"
                        value={space.eligible_user_emails.join(", ")}
                        onChange={(e) =>
                            setSpace(
                                new Space({
                                    ...space,
                                    eligible_user_emails: e.target.value.split(", "),
                                }),
                            )
                        }
                        disabled={space.is_public || false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="teams"
                        label="Eligible Team Names"
                        value={space.eligible_teams.join(", ")}
                        onChange={(e) =>
                            setSpace(
                                new Space({ ...space, eligible_teams: e.target.value.split(", ") }),
                            )
                        }
                        disabled={space.is_public || false}
                    />
                </Grid>

                {Object.entries(space.functions).map(([fnName, fnId], index) => (
                    <Grid item xs={12} key={index}>
                        <Paper elevation={3}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label="Action Name"
                                        value={fnName}
                                        onChange={(e) => handleActionNameChange(e, fnName)}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        options={fns}
                                        getOptionLabel={(option: Fn) =>
                                            option ? `${option.name} (id: ${option.fn_id})` : ""
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label="Choose saved pipeline" />
                                        )}
                                        value={fns.find((fn) => fn.fn_id === fnId) || null}
                                        onChange={(event, newValue) => {
                                            setSpace(
                                                new Space({
                                                    ...space,
                                                    functions: {
                                                        ...space.functions,
                                                        [fnName]: newValue ? newValue.fn_id : "",
                                                    },
                                                }),
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    container
                                    justifyContent="center"
                                    alignItems="center">
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleRemoveAction(fnName)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                        </Paper>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button color="primary" onClick={handleAddAction}>
                        <AddCircleOutlineIcon /> Add Function
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        className="w-full"
                        variant="contained"
                        type="submit"
                        onClick={handleSave}
                        sx={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            color: `${colors.greenAccent[500]} !important`,
                            background: `${colors.greenAccent[900]} !important`,
                            "&:hover": `${colors.greenAccent[800]} !important`,
                        }}>
                        Save Space
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SpaceEditorPage;
