import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Card, CardContent, Container, IconButton, Typography } from "@mui/material";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../auth/firebase";
import Header from "../components/Header";
import { fetch_with_auth } from "../components/Util";
import { tokens } from "../theme";
import { UserProfile } from "../types/UserProfile";

interface FileListProps {
    userProfile: UserProfile;
    files: string[];
    onDelete: (fileName: string) => void;
}

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
    return (
        <List>
            {files.map((file, index) => (
                <ListItem key={index}>
                    <ListItemText primary={file} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

const DataPage: React.FC = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [files, setFiles] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const auth = getAuth(app);

    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const fetchFiles = async (id_token: string) => {
        const response = await fetch_with_auth(`list_all_files`, id_token, "GET");
        setFiles(response.files);
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFiles(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    const handleFileUpload = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch_with_auth(
            `upload_file`,
            userProfile.id_token || "",
            "POST",
            formData,
        );

        fetchFiles(userProfile.id_token || "");
    };

    const onSelectFile = async () => {
        if (!inputRef.current?.files?.[0]) {
            console.error("No file selected");
            return;
        }
        const file = inputRef.current.files[0];
        inputRef.current.value = ""; // clear files
        handleFileUpload(file);
    };

    const onDelete = async (fileName: string) => {
        const response = await fetch_with_auth(
            `delete_file?&file_name=${fileName}`,
            userProfile.id_token || "",
            "DELETE",
        );

        setFiles(files.filter((file) => file !== fileName));
        fetchFiles(userProfile.id_token || "");
    };

    return (
        <Container className="min-h-screen min-w-full">
            <Header title="Data" subtitle="Manage your uploaded files"></Header>

            <Card>
                <CardContent>
                    <div className="flex flex-row items-center justify-between">
                        <Typography variant="h5">Your Uploaded Files</Typography>
                    </div>
                    <div className="flex flex-col space-y-3">
                        <FileList userProfile={userProfile} files={files} onDelete={onDelete} />
                    </div>

                    <div className="mt-3 flex flex-row items-center justify-between space-x-3">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => inputRef.current?.click()}
                            sx={{
                                color: `${colors.primary[100]} !important`,
                                background: `${colors.primary[400]} !important`,
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                },
                            }}>
                            <UploadFileIcon sx={{ marginRight: "0.5rem" }} />
                            Upload File
                        </Button>

                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={inputRef}
                            onChange={onSelectFile}
                        />
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default DataPage;
