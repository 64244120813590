import { MouseEvent } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { User } from "firebase/auth";

interface LoginButtonProps {
    currentUser: User | null;
    handleSignOut: () => void;
    handleSignIn: () => void;
    collapsed: boolean;
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: HTMLElement | null) => void;
}

export function LoginButton({
    currentUser,
    handleSignIn,
    anchorEl,
    setAnchorEl,
    handleSignOut,
    collapsed,
}: LoginButtonProps) {
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("setting anchorEl", event);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (currentUser) {
        return (
            <div className="flex h-16 w-full !border-black p-3">
                <div className="flex shrink-0 items-center justify-center">
                    <img
                        alt="profile"
                        src={currentUser.photoURL || ""}
                        referrerPolicy="no-referrer"
                        style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                        }}
                    />
                </div>
                {!collapsed && (
                    <div className="flex w-full overflow-hidden whitespace-nowrap">
                        <div className="flex flex-grow items-center pl-2">
                            <p>{currentUser.displayName}</p>
                        </div>
                        <IconButton
                            id="basic-button"
                            aria-label="delete"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            size="large"
                            onClick={handleMenuClick}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                )}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}>
                    <MenuItem
                        onClick={(event: MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            handleSignOut();
                        }}>
                        Sign out
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    return (
        <>
            <button onClick={handleSignIn}>Sign in</button>
        </>
    );
}
