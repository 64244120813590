import React, { DragEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { tokens } from "../theme";
import OperatorTooltip from "./OperatorTooltip";

interface SelectorProps {
    ops: OperatorDeclaration[];
}

const DagDiagramOperatorSelector: React.FC<SelectorProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Arrange ops by category
    const opsByCategory = props.ops.reduce<Record<string, OperatorDeclaration[]>>((groups, op) => {
        const category = op.category;
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(op);
        return groups;
    }, {});

    const onDragStart = (event: DragEvent, nodeData: OperatorDeclaration) => {
        event.dataTransfer.setData("application/reactflow", "operator");
        event.dataTransfer.setData("application/reactflow-data", JSON.stringify(nodeData));
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <aside
            style={{
                background: colors.primary[400],
                border: `1px solid ${colors.primary[300]}`,
                padding: "0px",
                fontSize: "12px",
            }}>
            <div className="m-4">Available operators, drag to use</div>
            <div className="min-w-full">
                {opsByCategory &&
                    Object.entries(opsByCategory).map(([category, ops], index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography className="text-xs" style={{ wordBreak: "break-word" }}>
                                    {category}
                                </Typography>
                            </AccordionSummary>
                            {ops.map((declaration, index) => (
                                <AccordionDetails
                                    key={index}
                                    style={{
                                        padding: "0px 5px 0px 5px",
                                    }}>
                                    <div
                                        key={index}
                                        className="h-auto"
                                        onDragStart={(event) => onDragStart(event, declaration)}
                                        draggable
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            border: `1px solid ${colors.primary[300]}`,
                                            borderRadius: "2px",
                                            marginBottom: "5px",
                                            cursor: "grab",
                                            overflow: "hidden",
                                            whiteSpace: "normal",
                                            padding: "5px",
                                        }}>
                                        {declaration.name}
                                        <OperatorTooltip declaration={declaration} />
                                    </div>
                                </AccordionDetails>
                            ))}
                        </Accordion>
                    ))}
            </div>
        </aside>
    );
};

export default DagDiagramOperatorSelector;
