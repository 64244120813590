import { useEffect, useState } from "react";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { Link as RouterLink } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Ring } from "@uiball/loaders";

import app from "../auth/firebase";
import Header from "../components/Header";
import { fetch_with_auth } from "../components/Util";
import { tokens } from "../theme";
import { Fn } from "../types/Fn";
import { UserProfile } from "../types/UserProfile";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
    },
});

const formatDateToLocal = (date: Date | null) => {
    if (date) {
        try {
            return new Intl.DateTimeFormat("default", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZoneName: "short",
            }).format(date);
        } catch (e) {
            console.error("Invalid date value:", date);
            return "N/A";
        }
    } else {
        return "N/A";
    }
};

const FunctionsPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    const [fns, setFns] = useState<Fn[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchFunctions = async (id_token: string) => {
        setIsLoading(true);
        try {
            const data = await fetch_with_auth("fn", id_token, "GET");
            const newFns = data.map((element: any) => new Fn(element));
            setFns(newFns);
            setIsLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFunctions(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    return (
        <div className="min-w-full">
            <Header title="Functions" subtitle="List of all available functions"></Header>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Function ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Created</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <Ring size={25} lineWeight={5} speed={2} color="black" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            fns.map((fn, index) => (
                                <TableRow key={index} sx={{ height: "auto" }}>
                                    <TableCell>{fn.fn_id}</TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/function_editor?fn_id=${fn.fn_id}`}
                                            component={RouterLink}
                                            rel="noreferrer"
                                            sx={{
                                                textDecoration: "underline",
                                                color: colors.blueAccent[500],
                                            }}>
                                            {fn.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{fn.description}</TableCell>
                                    <TableCell>{fn.author_name}</TableCell>
                                    <TableCell>
                                        {formatDateToLocal(
                                            fn.created_ts ? new Date(fn.created_ts) : null,
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FunctionsPage;
