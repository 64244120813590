import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";

import app from "../auth/firebase";
import Header from "../components/Header";
import { tokens } from "../theme";
import { Team } from "../types/Team";
import { UserProfile } from "../types/UserProfile";

const TeamPage: React.FC = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [teams, setTeams] = useState<Team[]>([]);
    const [newUserEmail, setNewUserEmail] = useState<string>("");

    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const [openDialog, setOpenDialog] = useState(false);
    const [newTeamName, setNewTeamName] = useState("");

    const loadTeams = async (id_token: string) => {
        const loadedTeams = await Team.get_teams_for_user(id_token);
        setTeams(loadedTeams);
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            loadTeams(userProfile.id_token);
        }
    }, [userProfile.id_token]);

    const addUser = async (teamName: string) => {
        const team = teams.find((team) => team.name === teamName);
        if (team) {
            const updatedTeam = await team.add_user(userProfile.id_token || "", newUserEmail);
            // Replace the old team with the updated team in the state
            setTeams(teams.map((t) => (t.name === teamName ? updatedTeam : t)));
            setNewUserEmail("");
        }
    };

    const removeUser = async (teamName: string, user_email: string) => {
        const team = teams.find((team) => team.name === teamName);
        if (team) {
            const updatedTeam = await team.remove_user(userProfile.id_token || "", user_email);
            // Replace the old team with the updated team in the state
            setTeams(teams.map((t) => (t.name === teamName ? updatedTeam : t)));
        }
    };

    const addAdmin = async (teamName: string) => {
        const team = teams.find((team) => team.name === teamName);
        if (team) {
            const updatedTeam = await team.add_user(userProfile.id_token || "", newUserEmail, true);
            // Replace the old team with the updated team in the state
            setTeams(teams.map((t) => (t.name === teamName ? updatedTeam : t)));
            setNewUserEmail("");
        }
    };

    const removeAdmin = async (teamName: string, user_email: string) => {
        const team = teams.find((team) => team.name === teamName);
        if (team) {
            const updatedTeam = await team.remove_admin(userProfile.id_token || "", user_email);
            // Replace the old team with the updated team in the state
            setTeams(teams.map((t) => (t.name === teamName ? updatedTeam : t)));
        }
    };

    const addTeam = async () => {
        try {
            const newTeam = await Team.create_new(userProfile.id_token || "", newTeamName);
            setTeams([...teams, newTeam]);
            setOpenDialog(false);
            setNewTeamName("");
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewTeamName("");
    };

    const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTeamName(event.target.value);
    };

    return (
        <Container className="min-h-screen min-w-full">
            <Header
                title="Teams"
                subtitle="Agents can be accessible to team or individual users"></Header>

            <IconButton onClick={handleOpenDialog}>
                <AddIcon />
            </IconButton>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        backgroundColor: colors.primary[400],
                    },
                }}>
                <DialogTitle id="form-dialog-title">New Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter the name for the new team.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Team Name"
                        type="text"
                        fullWidth
                        value={newTeamName}
                        onChange={handleTeamNameChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} sx={{ color: colors.redAccent[500] }}>
                        Cancel
                    </Button>
                    <Button onClick={addTeam} sx={{ color: colors.greenAccent[400] }}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {teams.map((team, index) => (
                <Card key={index} sx={{ marginBottom: 2, background: colors.primary[400] }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {team.name}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="h6">Members</Typography>
                                {team.members.map((member, memberIndex) => (
                                    <div key={memberIndex}>
                                        {member}
                                        <IconButton onClick={() => removeUser(team.name, member)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">Admins</Typography>
                                {team.admins.map((admin, adminIndex) => (
                                    <div key={adminIndex}>
                                        {admin}
                                        <IconButton onClick={() => removeAdmin(team.name, admin)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={newUserEmail}
                                    onChange={(event) => setNewUserEmail(event.target.value)}
                                    placeholder="Enter user email"
                                    fullWidth
                                />
                                <IconButton onClick={() => addUser(team.name)}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton onClick={() => addAdmin(team.name)}>
                                    <AddModeratorIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </Container>
    );
};

export default TeamPage;
