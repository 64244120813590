export class Authz {
    is_public: boolean;
    emails: string[];
    teams: string[];

    constructor(d: Partial<Authz>) {
        this.is_public = d.is_public || false;
        this.emails = d.emails || [];
        this.teams = d.teams || [];
    }
}

//export type { Authz };
