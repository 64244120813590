import React, { useEffect, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, Container, TextField, Typography, useTheme } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Collapse } from "react-collapse";
import toast from "react-hot-toast";

import app from "../auth/firebase";
import { unlinkProvider } from "../auth/login";
import Header from "../components/Header";
import { apiUrl } from "../components/Util";
import { tokens } from "../theme";
import { UserProfile } from "../types/UserProfile";

interface Secret {
    secret_type: string;
    value?: string;
    created_ts?: Date;
    error_msg?: string;
}

interface Section {
    exampleValue: string;
    isOpen: boolean;
    secret_type: string;
    value?: string;
    created_ts?: Date;
    error_msg?: string;
}

const defaultSection = {
    value: "",
    created_ts: new Date(),
    error_msg: "",
};

const SecretsPage: React.FC = () => {
    const auth = getAuth(app);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    const [secrets, setSecrets] = useState<Secret[]>([]);

    const [sections, setSections] = useState<Section[]>([
        {
            secret_type: "openai_token",
            exampleValue: `sk-OAFEG23eGFSGrgewrgG235g35345h45hlj5bAulMcIH`,
            isOpen: false,
            ...defaultSection,
        },
        {
            secret_type: "hunter_api_key",
            exampleValue: `0c624b597f62bbcxxxxxaaaaabbbbbddddd787f2`,
            isOpen: false,
            ...defaultSection,
        },
        {
            secret_type: "bing_search_key",
            exampleValue: `d7f7baaaaaa111229de96afdd9ffab71`,
            isOpen: false,
            ...defaultSection,
        },
        {
            secret_type: "twitter_api_key",
            exampleValue: `{"ACCESS_TOKEN": "xxxxx",
"ACCESS_SECRET": "xxxxxx"}`,
            isOpen: false,
            ...defaultSection,
        },
        {
            secret_type: "github_access_token",
            exampleValue: `ghp_rKNEJ1cvhr44zc9IRlpboftulwG2Zg2FaqTo`,
            isOpen: false,
            ...defaultSection,
        },
    ]);

    const fetchSecrets = async (user_id: string) => {
        try {
            const response = await fetch(`${apiUrl}/secret?user_id=${user_id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            const updatedSections = sections.map((section) => {
                const matchingData = data.find(
                    (d: Secret) => d.secret_type === section.secret_type,
                );
                if (matchingData) {
                    return {
                        ...section,
                        value: matchingData.value,
                        created_ts: matchingData.created_ts,
                        error_msg: matchingData.error_msg,
                        isOpen: true,
                    };
                } else {
                    return section;
                }
            });

            setSections(updatedSections);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchSecrets(userProfile.user_id!);
        }
    }, [userProfile.id_token]);

    const saveSecret = async (section: Section) => {
        console.log(`saveSecret called: ${JSON.stringify(section)}`);
        if (!section.value) {
            // Inconveniently in this case Google Cloud Spanner is not
            // updating the existing value to empty string (treats it as null and thus invalid for update)
            // and we got to delete it manually instead.
            await deleteSecret(section);
            return;
        }

        try {
            toast.loading("Saving secret");
            const response = await fetch(`${apiUrl}/secret`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...section,
                    user_id: userProfile.user_id,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                toast.dismiss();
                toast.success("Secret saved");
            }
        } catch (error) {
            console.error(error);
            toast.dismiss();
            toast.error(`Something went wront when saving secret: ${error}`);
        }
    };

    const deleteSecret = async (section: Section) => {
        try {
            toast.loading("Deleting secret");

            const response = await fetch(
                `${apiUrl}/secret?user_id=${userProfile.user_id}&secret_type=${section.secret_type}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (response.ok && section.secret_type === "twitter_api_key") {
                unlinkProvider("twitter.com");
            }

            if (response.ok && section.secret_type === "github_access_token") {
                unlinkProvider("github.com");
            }

            if (response.ok) {
                toast.dismiss();
                toast.success("Secret deleted");

                const updatedSections = sections.map((s) => {
                    if (s.secret_type === section.secret_type) {
                        return { ...s, value: "" };
                    }
                    return s;
                });
                setSections(updatedSections);
            } else {
                toast.dismiss();
                toast.error(`Something went wront when deleting secret`);
            }
        } catch (error) {
            console.error(error);
            toast.dismiss();
            toast.error(`Something went wront when deleting secret: ${error}`);
        }
    };

    const toggleCollapse = (index: number) => {
        const newSections = [...sections];
        newSections[index].isOpen = !newSections[index].isOpen;
        setSections(newSections);
    };

    return (
        <>
            <Container className="min-h-screen min-w-full space-y-4">
                <Header title="Secrets" subtitle="tssshh..."></Header>
                {sections.map((section, index) => {
                    return (
                        <Card
                            key={section.secret_type}
                            className="p-6"
                            sx={{ background: colors.primary[400] }}>
                            <Typography
                                variant="h2"
                                style={{
                                    fontFamily: "monospace",
                                    cursor: "pointer",
                                }}
                                onClick={() => toggleCollapse(index)}>
                                {section.secret_type}
                                {!section.isOpen && <ExpandMoreIcon />}
                                {section.isOpen && <ExpandLessIcon />}
                            </Typography>
                            <Collapse isOpened={section.isOpen}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    className="flex-col pb-2 pt-2 md:flex-row">
                                    <Box flex="1">
                                        <TextField
                                            style={{
                                                fontFamily: "monospace",
                                                width: "100%",
                                                margin: "5px",
                                            }}
                                            rows={8}
                                            multiline
                                            variant="outlined"
                                            value={section.value || ""}
                                            onChange={(e) => {
                                                console.log(`onChange!`);
                                                const newValue = e.target.value;
                                                const newSections = [...sections];
                                                const sectionToUpdate = newSections.find(
                                                    (s) => s.secret_type === section.secret_type,
                                                );
                                                if (sectionToUpdate) {
                                                    sectionToUpdate.value = newValue;
                                                    console.log(
                                                        `calling setSections(newSections);`,
                                                    );
                                                    setSections(newSections);
                                                }
                                            }}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: colors.primary[300], // Change this to your desired border color
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box flex="1" marginLeft={2}>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                fontFamily: "monospace",
                                                cursor: "pointer",
                                            }}>
                                            Example configuration:
                                        </Typography>
                                        <Box
                                            component="pre"
                                            style={{
                                                fontFamily: "monospace",
                                                whiteSpace: "pre-wrap",
                                            }}>
                                            {section.exampleValue}
                                        </Box>
                                    </Box>
                                </Box>

                                <div className="space-x-3">
                                    <Button
                                        onClick={() => deleteSecret(section)}
                                        style={{
                                            backgroundColor: colors.redAccent[700], // Change this to your desired background color
                                            color: colors.primary[100], // Change this to your desired text color
                                        }}>
                                        Delete Secret
                                    </Button>

                                    <Button
                                        onClick={() => saveSecret(section)}
                                        style={{
                                            backgroundColor: colors.greenAccent[700], // Change this to your desired background color
                                            color: colors.primary[100], // Change this to your desired text color
                                        }}>
                                        Save
                                    </Button>
                                </div>
                            </Collapse>
                        </Card>
                    );
                })}
            </Container>
        </>
    );
};

export default SecretsPage;
