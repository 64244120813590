import React, { useEffect, useRef, useState } from "react";

import ReactJson, { InteractionProps } from "@microlink/react-json-view";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import toast from "react-hot-toast";

import { tokens } from "../theme";
import { FnRun } from "../types/FnRun";
import { UserProfile } from "../types/UserProfile";
import { fetch_with_auth } from "./Util";

interface Message {
    user: "bot" | "user";
    text: any;
    isJson: boolean;
    executed?: boolean;
    runId?: string;
}

interface SpaceChatProps {
    userProfile: UserProfile;
    selectedSpaceId: string;
    selectedRows: any[];
    selectedTable: string;
}

type FnRunDictionary = { [key: number]: FnRun };

const SpaceChat: React.FC<SpaceChatProps> = ({
    selectedSpaceId,
    userProfile,
    selectedRows,
    selectedTable,
}: SpaceChatProps) => {
    const [input, setInput] = useState<string>("");
    const [messages, setMessages] = useState<Message[]>([]);
    const [plRuns, setPlRuns] = useState<FnRunDictionary>({});
    const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const updatePlRuns = (id: number, fnRun: FnRun) => {
        setPlRuns((prevState) => ({
            ...prevState,
            [id]: fnRun,
        }));
    };

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!input.trim()) return;

        setMessages((prev) => [...prev, { user: "user", text: input, isJson: false }]);

        try {
            const data = await fetch_with_auth("space_chat", userProfile.id_token || "", "POST", {
                space_id: selectedSpaceId,
                user_input: input,
                selected_rows: selectedRows,
                current_table: selectedTable,
            });

            setMessages((prev) => [...prev, { user: "bot", text: data, isJson: true }]);
        } catch (error: any) {
            toast.error(`Error: ${error.message}`);
        }

        setInput("");
    };

    const handleExecuteFlow = async (index: number) => {
        try {
            const message = messages[index];
            const response = await fetch_with_auth(
                "space_start_flow",
                userProfile.id_token || "",
                "POST",
                {
                    space_id: selectedSpaceId,
                    flow: JSON.stringify(message.text),
                },
            );

            const runId = response.run_id;

            setMessages((prev) => {
                const newMessages = [...prev];
                newMessages[index] = { ...message, runId, executed: true };
                return newMessages;
            });

            toast.success("Flow executed successfully.");

            // Start polling for the pipeline status
            pollPipelineStatus(runId, index);
        } catch (error: any) {
            toast.error(`Error executing flow: ${error.message}`);
        }
    };

    const pollPipelineStatus = (runId: string, index: number) => {
        let interval: ReturnType<typeof setInterval> | null = null;
        const checkStatus = async () => {
            try {
                const newPlRun = await FnRun.loadRun(runId);
                if (newPlRun) {
                    updatePlRuns(index, newPlRun);
                    if (!newPlRun.isRunning()) {
                        clearInterval(interval as ReturnType<typeof setInterval>);
                        toast.success(`Pipeline ${newPlRun.state}.`);

                        // Update only the outputs part of the message
                        setMessages((prev) => {
                            const newMessages = [...prev];
                            const updatedText = newMessages[index].text.map((item: any) => {
                                item.outputs = newPlRun.run_outputs;
                                return item;
                            });
                            newMessages[index] = {
                                ...newMessages[index],
                                text: updatedText,
                                executed: true,
                            };
                            return newMessages;
                        });
                    }
                }
            } catch (error: any) {
                clearInterval(interval as ReturnType<typeof setInterval>);
                toast.error(`Error checking pipeline status: ${error.message}`);
            }
        };

        interval = setInterval(checkStatus, 3000);
    };

    const handleKillPipeline = async (runId: string) => {
        try {
            const response = await fetch_with_auth(
                "try_kill_pipeline_run",
                userProfile.id_token || "",
                "POST",
                {
                    user_id: userProfile.user_id,
                    run_id: runId,
                },
            );

            if (!response.ok) {
                throw new Error("Failed to kill pipeline");
            }

            toast.success("Pipeline run queued for termination.");
        } catch (error: any) {
            toast.error(`Error killing pipeline: ${error.message}`);
        }
    };

    const handleJsonEdit = (index: number, edit: any) => {
        setMessages((prev) => {
            const newMessages = [...prev];
            newMessages[index] = { ...newMessages[index], text: edit.updated_src };
            return newMessages;
        });
    };

    return (
        <Box
            sx={{
                height: "68vh",
                display: "flex",
                flexDirection: "column",
                bgcolor: colors.primary[400],
                borderRadius: "5px",
                border: "1px solid",
                borderColor: colors.primary[300],
                minWidth: "30rem",
            }}
            className="min-w-full p-4">
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                }}>
                {messages.map((message, i) => (
                    <Box
                        key={i}
                        sx={{
                            alignSelf: message.user === "bot" ? "flex-start" : "flex-end",
                            m: 1,
                            p: 1,
                            bgcolor:
                                message.user === "bot"
                                    ? colors.blueAccent[900]
                                    : colors.greenAccent[900],
                            borderRadius: "5px",
                        }}>
                        {message.isJson ? (
                            <Box>
                                <ReactJson
                                    src={message.text}
                                    onEdit={(edit: InteractionProps) => handleJsonEdit(i, edit)}
                                    onAdd={(edit: InteractionProps) => handleJsonEdit(i, edit)}
                                    onDelete={(edit: InteractionProps) => handleJsonEdit(i, edit)}
                                    theme={message.executed ? "bright:inverted" : "bright:inverted"}
                                    style={{
                                        backgroundColor: message.executed
                                            ? "antiquewhite"
                                            : "white",
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleExecuteFlow(i)}
                                    sx={{ mt: 1 }}
                                    disabled={message.executed}>
                                    Execute Flow
                                </Button>
                                {plRuns[i] ? <>{`Execution state: ${plRuns[i].state}`}</> : null}
                                {message.executed && message.runId && (
                                    <>
                                        {plRuns[i] &&
                                        plRuns[i].isRunning() &&
                                        plRuns[i].state !== "TERMINATING" ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleKillPipeline(message.runId!)}
                                                sx={{ mt: 1, ml: 1 }}>
                                                Kill Function Execution
                                            </Button>
                                        ) : null}
                                        <Button
                                            variant="contained"
                                            color="success"
                                            href={`/function_editor?run_id=${message.runId}`}
                                            target="_blank" // Open in a new tab
                                            rel="noopener noreferrer" // Security feature to prevent the new page from accessing the window.opener property
                                            sx={{ mt: 1, ml: 1 }}>
                                            Open Function Execution in Pipeline Editor
                                        </Button>
                                    </>
                                )}
                            </Box>
                        ) : (
                            <Typography variant="body1">{message.text}</Typography>
                        )}
                    </Box>
                ))}
                <div ref={endOfMessagesRef} />
            </Box>
            <form className="flex flex-row" onSubmit={handleSubmit}>
                <TextField
                    className="flex"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Send a message..."
                    sx={{ flexGrow: 1, mr: 2 }}
                    disabled={false}
                    inputRef={inputRef}
                />
                <Button type="submit" disabled={false}>
                    <SendIcon />
                </Button>
            </form>
        </Box>
    );
};

export default SpaceChat;
