import React from "react";

import { JSONTree } from "react-json-tree";
import { Edge } from "reactflow";

interface EdgeDataPopupProps {
    edge: Edge;
    stepOutputs: Record<string, Record<string, any>>;
}

const EdgeDataPopup: React.FC<EdgeDataPopupProps> = ({ edge, stepOutputs }) => {
    const stepId = typeof edge.source === "string" ? edge.source : "";
    const outputHandle = edge.sourceHandle ?? "defaultHandle";
    const stepOutput = stepOutputs[stepId];
    const outputData = stepOutput ? stepOutput[outputHandle] : null;

    return <div id="edge-data-popup">{outputData && <JSONTree data={outputData} />}</div>;
};

export default EdgeDataPopup;
