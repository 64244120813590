import { HelpOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { tokens } from "../theme";

interface OperatorTooltipProps {
    declaration: OperatorDeclaration;
}

const OperatorTooltip: React.FC<OperatorTooltipProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Tooltip
            title={
                <div style={{ fontSize: "16px" }}>
                    <div
                        style={{
                            border: `1px solid ${colors.grey[300]}`,
                            padding: "10px",
                        }}>
                        <p>Description:</p>
                        <p>{props.declaration.description}</p>
                    </div>
                    <div
                        style={{
                            border: `1px solid ${colors.grey[300]}`,
                            padding: "10px",
                        }}>
                        <p>Parameters:</p>
                        <ul>
                            {props.declaration.parameters.map((param, index) => (
                                <li key={index}>
                                    {param.name}: {param.data_type}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        style={{
                            border: `1px solid ${colors.grey[300]}`,
                            padding: "10px",
                        }}>
                        <p>Inputs:</p>
                        <ul>
                            {props.declaration.inputs.map((input, index) => (
                                <li key={index}>
                                    {input.name}: {input.data_type}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        style={{
                            border: `1px solid ${colors.grey[300]}`,
                            padding: "10px",
                        }}>
                        <p>Outputs:</p>
                        <ul>
                            {props.declaration.outputs.map((output, index) => (
                                <li key={index}>
                                    {output.name}: {output.data_type}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            }>
            <HelpOutline />
        </Tooltip>
    );
};

export default OperatorTooltip;
