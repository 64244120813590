import React from "react";

import { Box, Container, Typography } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Container maxWidth="md">
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Privacy Policy for Spesh AI
                    </Typography>
                    <Typography paragraph>Last updated: December 13th 2023</Typography>

                    <Typography variant="h6">Introduction</Typography>
                    <Typography paragraph>
                        At Spesh.ai, we are committed to maintaining the trust and confidence of
                        visitors to our web application. In this Privacy Policy, we’ve provided
                        detailed information on when and why we collect personal information, how we
                        use it, and how we keep it secure.
                    </Typography>

                    <Typography variant="h6">Information We Collect</Typography>
                    <Typography paragraph>
                        Information collected by using Spesh.ai may include your name, email
                        address, and any other personal information you provide. As part of our
                        service, we also access and manage Google Spreadsheets on your behalf,
                        strictly within the scope of your provided instructions and permissions.
                    </Typography>

                    <Typography variant="h6">Use of Your Information</Typography>
                    <Typography paragraph>
                        The information you provide is used to fulfill your specific request, such
                        as creating or updating a Google Spreadsheet. It is not used for any other
                        purpose unless explicitly provided by you.
                    </Typography>

                    <Typography variant="h6">Data Storage and Security</Typography>
                    <Typography paragraph>
                        Your information is securely stored and we take all necessary measures to
                        protect it from unauthorized access or disclosure. Our data storage and
                        security protocols are designed to ensure the confidentiality and integrity
                        of your personal information.
                    </Typography>

                    <Typography variant="h6">Third-Party Services</Typography>
                    <Typography paragraph>
                        We use Firebase for authentication and Google APIs for spreadsheet
                        management. We do not share your personal information with other third-party
                        services without your consent.
                    </Typography>

                    <Typography variant="h6">Your Rights</Typography>
                    <Typography paragraph>
                        You have the right to access, update, delete, or request a copy of your
                        personal information that we hold. If you wish to exercise any of these
                        rights, please contact us at [feedback@spesh.ai].
                    </Typography>

                    <Typography variant="h6">Changes to this Privacy Policy</Typography>
                    <Typography paragraph>
                        We may update this privacy policy from time to time. We encourage you to
                        review it frequently.
                    </Typography>

                    <Typography variant="h6">Contact Us</Typography>
                    <Typography paragraph>
                        If you have any questions about our privacy policy or the information we
                        hold about you, please contact us at [feedback@spesh.ai].
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
