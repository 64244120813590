const LogoIcon = ({ width = 22, fill = "#7A5900" }) => (
    <svg viewBox="0 0 114 114" width={width + "px"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M71.6431 56.2461C71.6431 64.5303 64.9274 71.2461 56.6431 71.2461C48.3589 71.2461 41.6431 64.5303 41.6431 56.2461C41.6431 47.9618 48.3589 41.2461 56.6431 41.2461C64.9274 41.2461 71.6431 47.9618 71.6431 56.2461Z"
            fill="#FF9100"
        />
        {/* <path
      d='M95.4863 15.5917C97.5803 17.5823 97.2437 20.9261 94.9616 22.6981C92.8715 24.3209 89.8994 24.0232 87.9474 22.2366C75.9695 11.2735 58.5869 6.91142 42.0545 12.321C33.4213 15.1458 26.1762 20.2569 20.7708 26.7637C19.1461 28.7193 16.3999 29.453 14.1322 28.3034C11.4522 26.9449 10.5276 23.5482 12.4032 21.2009C19.0382 12.8971 28.0881 6.36915 38.9447 2.8168C59.3939 -3.87435 80.9136 1.73869 95.4863 15.5917Z'
      fill={fill}
    /> */}
        <path
            d="M99.7294 39.3416C98.8327 37.0792 99.4004 34.4442 101.323 32.9517C103.796 31.0315 107.415 31.7776 108.623 34.6662C109.205 36.0588 109.736 37.4855 110.214 38.9448C119.918 68.6016 103.743 100.51 74.0858 110.214C44.429 119.918 12.5207 103.743 2.8168 74.0858C-0.735556 63.2292 -0.819872 52.0709 1.95568 41.8106C2.74028 38.9102 6.0265 37.6482 8.70648 39.0067C10.9742 40.1563 12.0059 42.805 11.3888 45.2715C9.33589 53.4777 9.4961 62.3427 12.321 70.976C20.3074 95.3838 46.5681 108.696 70.976 100.71C95.3838 92.7232 108.696 66.4624 100.71 42.0546C100.408 41.1343 100.081 40.2298 99.7294 39.3416Z M95.4863 15.5917C97.5803 17.5823 97.2437 20.9261 94.9616 22.6981C92.8715 24.3209 89.8994 24.0232 87.9474 22.2366C75.9695 11.2735 58.5869 6.91142 42.0545 12.321C33.4213 15.1458 26.1762 20.2569 20.7708 26.7637C19.1461 28.7193 16.3999 29.453 14.1322 28.3034C11.4522 26.9449 10.5276 23.5482 12.4032 21.2009C19.0382 12.8971 28.0881 6.36915 38.9447 2.8168C59.3939 -3.87435 80.9136 1.73869 95.4863 15.5917Z"
            fill="currentColor"
        />
        <path
            d="M41.4162 78.4879C41.598 77.9614 41.4163 77.3802 40.9819 77.0314C29.8677 68.1082 28.0915 51.8647 37.0147 40.7505C45.9378 29.6363 62.1813 27.8601 73.2956 36.7833C76.0235 38.9734 78.1889 41.6046 79.7716 44.4926C80.922 46.5919 83.1213 48.0304 85.5009 47.7702C88.6812 47.4225 90.803 44.2759 89.4191 41.3915C87.1743 36.7129 83.8728 32.4512 79.5561 28.9855C64.1353 16.6047 41.5976 19.0691 29.2169 34.49C16.8361 49.9108 19.3005 72.4484 34.7214 84.8292C36.4953 86.2534 39.0483 85.345 39.7908 83.1948L41.4162 78.4879Z M51.3617 86.4518C50.3614 89.3483 52.123 92.4992 55.1829 92.664C66.3092 93.2633 77.5527 88.6764 85.0608 79.3247C88.5265 75.008 90.829 70.1335 92.0096 65.0804C92.7375 61.965 89.9858 59.3514 86.8055 59.6991C84.4259 59.9593 82.5895 61.8391 81.9199 64.1375C80.9987 67.2993 79.4532 70.3362 77.263 73.0642C72.1598 79.4204 64.6623 82.7225 57.0993 82.7156C54.5951 82.7134 52.179 84.0848 51.3617 86.4518Z"
            fill="currentColor"
        />
    </svg>
);

export default LogoIcon;
