import React, { useState } from "react";

import { Box, Button, Container, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import toast from "react-hot-toast";

import Header from "../components/Header";
import { apiUrl } from "../components/Util";

const FeedbackPage = () => {
    const [feedbackContent, setFeedbackContent] = useState("");
    const [email, setEmail] = useState("");
    const [runId, setRunId] = useState("");

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const linkColor = isDarkMode ? "white" : "black";

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await fetch(`${apiUrl}/record_feedback`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: new URLSearchParams({
                    content: feedbackContent,
                    email: email || "",
                    run_id: runId || "",
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to submit feedback: ${response.statusText}`);
            }

            const data = await response.json();
            toast.success("Feedback submitted successfully.");
            setFeedbackContent("");
        } catch (error) {
            console.error(error);
            toast.error("Failed to submit feedback. Please try again later.");
        }
    };

    return (
        <>
            <Container className="min-h-screen min-w-full" maxWidth="sm">
                <Header
                    title="Feedback"
                    subtitle="Leave your email if you want us to get back to you!"></Header>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Feedback or Request
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Feedback Content"
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    value={feedbackContent}
                                    onChange={(e) => setFeedbackContent(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email (optional)"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Run ID (optional)"
                                    value={runId}
                                    onChange={(e) => setRunId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="flex w-1/6"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: "grey" }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Box sx={{ mt: 2 }}>
                        <Typography>
                            Email us at{" "}
                            <Link
                                href="mailto:feedback@spesh.ai"
                                underline="hover"
                                style={{ color: linkColor }}>
                                feedback@spesh.ai
                            </Link>{" "}
                            or visit our{" "}
                            <Link
                                href="https://discord.gg/CrZuvzW7"
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="hover"
                                style={{ color: linkColor }}>
                                Discord community
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default FeedbackPage;
