import { Dispatch, SetStateAction } from "react";

import { fetch_with_auth } from "../components/Util";

interface FunctionsDictionary {
    [key: string]: string;
}

interface TablesDictionary {
    [key: string]: string[];
}

type StringDictionary = {
    [key: string]: string;
};

export class Space {
    space_id: string;
    name: string | null;
    description: string | null;
    is_public: boolean | null;
    author_id: string;
    author_name: string | null;
    created_ts: Date | null;
    eligible_user_emails: string[];
    eligible_teams: string[];
    functions: FunctionsDictionary;
    tables: TablesDictionary;

    constructor(space: Partial<Space>) {
        this.space_id = space.space_id || "";
        this.name = space.name || null;
        this.description = space.description || null;
        this.is_public = space.is_public || null;
        this.author_id = space.author_id || "";
        this.author_name = space.author_name || null;
        this.created_ts = space.created_ts || null;
        this.eligible_user_emails = space.eligible_user_emails || [];
        this.eligible_teams = space.eligible_teams || [];

        if (typeof space.functions === "string") {
            try {
                this.functions = JSON.parse(space.functions);
            } catch (error) {
                console.error("Parsing space.functions failed", error);
                this.functions = {};
            }
        } else {
            this.functions = space.functions || {};
        }

        if (typeof space.tables === "string") {
            try {
                this.tables = JSON.parse(space.tables);
            } catch (error) {
                console.error("Parsing space.tables failed", error);
                this.tables = {};
            }
        } else {
            this.tables = space.tables || {};
        }
    }

    update(setState: Dispatch<SetStateAction<Space>>, someValues: Partial<Space>) {
        const newInstance = new Space({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    static async loadSpace(space_id: string, id_token: string): Promise<Space> {
        const data = await fetch_with_auth(`space?space_id=${space_id}`, id_token, "GET");

        if (!data) {
            throw new Error("Invalid response data");
        }

        const space = new Space(data);
        return space;
    }

    static async delete_from_spanner(space_id: string, id_token: string) {
        await fetch_with_auth(`space?space_id=${space_id}`, id_token, "DELETE");
        return true;
    }

    async save_to_spanner(id_token: string) {
        return await fetch_with_auth("space", id_token, "POST", this);
    }

    static async get_spaces_for_user(id_token: string): Promise<Space[]> {
        const data = await fetch_with_auth("space", id_token, "GET");

        if (!Array.isArray(data)) {
            throw new Error("Invalid response data");
        }

        return data.map((spaceData: StringDictionary) => new Space(spaceData));
    }
}
