import React, { Dispatch } from "react";

import { HelpOutline, HighlightOff } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Input, MenuItem, Select, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Edge, Handle, Node, NodeResizer, Position } from "reactflow";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { tokens } from "../theme";
import { DagStepType, FnDef } from "../types/FnDef";
import OperatorTooltip from "./OperatorTooltip";

interface ParameterType {
    name: string;
    data_type: string;
    placeholder: string;
    value: string;
}

interface InputOutputType {
    name: string;
    data_type: string;
}

interface OperatorNodeData {
    name: string;
    inputs: InputOutputType[];
    parameters: ParameterType[];
    outputs: InputOutputType[];
    declaration: OperatorDeclaration;
    batch: boolean;

    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;

    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;

    fnDef: FnDef | null;
    setFnDef: Dispatch<React.SetStateAction<FnDef | null>>;
}

export interface OperatorNodeProps {
    data: OperatorNodeData;
    isConnectable: boolean;
    id: string;
    selected: boolean;
}

const OperatorNode: React.FC<OperatorNodeProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const additionalParametersExist = props.data.declaration.additional_parameters.some(
        (additionalParam) =>
            props.data.parameters.some((param) => param.name === additionalParam.name),
    );

    const [showAdditionalParameters, setShowAdditionalParameters] =
        React.useState(additionalParametersExist);

    const updateDag = (newDag: DagStepType[]) => {
        if (props.data.fnDef) {
            const newFnDef = {
                ...props.data.fnDef,
                dag: newDag,
            };
            props.data.fnDef.update(props.data.setFnDef, newFnDef);
        }
    };

    const onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.data.setNodes((prevNodes) => {
            const newNodes = prevNodes.filter((n) => n.id !== props.id);
            props.data.setEdges((prevEdges) => {
                const newEdges = prevEdges.filter(
                    (e) => e.source !== props.id && e.target !== props.id,
                );
                const newDag = nodesToDag(newNodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
            return newNodes;
        });
    };

    const handleNodeDataChange = (nodeDataChange: Partial<OperatorNodeData>) => {
        props.data.setEdges((edges) => {
            // This outer call of setEdges should not be needed but without it for some reason
            // handleNodeDataChange is seeing stale value of edges (an empty list) before any manipulation
            // is done with the diagram. To fix this weird bug we are wrapping the code inside of setEdges that would
            // ensure that most up-to-date edges are used and thus first call to handleNodeDataChange
            // would not delete all edges in the pipeline.
            props.data.setNodes((nodes) => {
                const updatedNodes = nodes.map((node) =>
                    node.id === props.id
                        ? { ...node, data: { ...node.data, ...nodeDataChange } }
                        : node,
                );
                const newDag = nodesToDag(updatedNodes, edges);
                updateDag(newDag);
                return updatedNodes;
            });
            return edges;
        });
    };

    const handleParameterChange = (parameterName: string, newValue: string) => {
        const parameterExists = props.data.parameters.find((param) => param.name === parameterName);
        let newParams: ParameterType[];
        if (parameterExists) {
            newParams = props.data.parameters.map((param) =>
                param.name === parameterName ? { ...param, value: newValue } : param,
            );
        } else {
            newParams = [
                ...props.data.parameters,
                {
                    name: parameterName,
                    value: newValue,
                    data_type: "", // It is ok to leave these empty because declarations are used as source of truth
                    // for parameter data_type and placeholder.
                    placeholder: "",
                },
            ];
        }

        handleNodeDataChange({ parameters: newParams });
    };

    const renderParameters = () => {
        const paramsToRender = showAdditionalParameters
            ? [
                  ...props.data.declaration.parameters,
                  ...props.data.declaration.additional_parameters,
              ]
            : [...props.data.declaration.parameters];

        return paramsToRender.map((declarationParameter) => {
            const parameter = props.data.parameters.find(
                (param) => param.name === declarationParameter.name,
            );

            if (declarationParameter.condition && declarationParameter.condition.trim() !== "") {
                const [conditionParamName, conditionValue] = declarationParameter.condition
                    .split("==")
                    .map((str) => str.trim());
                const conditionParameter = props.data.parameters.find(
                    (param) => param.name === conditionParamName,
                );

                if (!conditionParameter || String(conditionParameter.value) !== conditionValue) {
                    // Don't render this parameter if the condition doesn't hold.
                    return null;
                }
            }

            let inputElement;

            if (declarationParameter.data_type === "boolean") {
                // if parameter type is boolean, render a switch
                inputElement = (
                    <Checkbox
                        checked={parameter ? parameter.value === "true" : false}
                        onChange={(event) =>
                            handleParameterChange(
                                declarationParameter.name,
                                String(event.target.checked),
                            )
                        }
                    />
                );
            } else if (declarationParameter.data_type.startsWith("enum(")) {
                // if parameter type is enum, render a dropdown selector
                // get enum values from string

                const matchResult = declarationParameter.data_type.match(/\(([^)]+)\)/);
                let enumValues = [];
                if (matchResult !== null) {
                    enumValues = matchResult[1].split(",");
                } else {
                    enumValues = ["!parameter definition error!"];
                }

                inputElement = (
                    <Select
                        className="nodrag"
                        name={`${declarationParameter.name}-selector`}
                        value={parameter ? parameter.value || "" : ""}
                        onChange={(e) =>
                            handleParameterChange(declarationParameter.name, e.target.value)
                        }>
                        {enumValues.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                );
            } else {
                // otherwise, render regular input field
                inputElement = (
                    <Input
                        value={parameter ? parameter.value || "" : ""}
                        onChange={(e) =>
                            handleParameterChange(declarationParameter.name, e.target.value)
                        }
                        className="nodrag min-w-full"
                        type={declarationParameter.data_type}
                        placeholder={declarationParameter.placeholder}
                        multiline
                    />
                );
            }

            return (
                <div key={declarationParameter.name}>
                    <label>{declarationParameter.name}: </label>
                    <Tooltip
                        title={
                            <div style={{ fontSize: "16px" }}>
                                {declarationParameter.description}
                            </div>
                        }
                        placement="top">
                        <HelpOutline />
                    </Tooltip>
                    {inputElement}
                </div>
            );
        });
    };

    return (
        <>
            <NodeResizer isVisible={props.selected} minHeight={30} />
            <Box
                className="space-y-3 rounded-md p-4"
                style={{
                    border: `1px solid ${colors.primary[300]}`,
                    background: colors.primary[400],
                    height: "100%",
                    overflow: "hidden",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <b>{props.data.name}</b>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {props.data.declaration.allow_batch && (
                            <FormControlLabel
                                label="Batch Mode"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        checked={props.data.batch || false}
                                        onChange={(event) =>
                                            handleNodeDataChange({ batch: event.target.checked })
                                        }
                                    />
                                }
                            />
                        )}
                        <button
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                            }}
                            style={{
                                marginLeft: 8,
                            }}>
                            <OperatorTooltip declaration={props.data.declaration} />
                        </button>
                        <button
                            onClick={onRemove}
                            style={{
                                marginLeft: 8,
                            }}>
                            <HighlightOff />
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {props.data.inputs.map((input, i) => {
                        const commonProps = {
                            className: "source",
                            "data-handleid": input.name,
                            "data-handlepos": "top",
                            "data-nodeid": props.id,
                        };

                        return (
                            <Handle
                                type="target"
                                position={Position.Top}
                                style={{
                                    backgroundColor: "#555",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    left:
                                        props.data.inputs.length > 1
                                            ? `${
                                                  (100.0 / (props.data.inputs.length - 1.0 / 3)) *
                                                  (i + 1.0 / 3)
                                              }%`
                                            : "50%",
                                }}
                                id={input.name}
                                key={input.name}
                                isConnectable={props.isConnectable}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -120%)",
                                    }}>
                                    <Typography variant="body1" {...commonProps}>
                                        {input.name}
                                    </Typography>
                                    <Typography variant="caption" {...commonProps}>
                                        {`type=${
                                            props.data.batch
                                                ? input.data_type + "[]"
                                                : input.data_type
                                        }`}
                                    </Typography>
                                </div>
                            </Handle>
                        );
                    })}
                </div>
                {renderParameters()}

                {props.data.declaration.additional_parameters &&
                    props.data.declaration.additional_parameters.length > 0 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setShowAdditionalParameters((prevState) => !prevState);
                            }}>
                            {showAdditionalParameters
                                ? "Hide additional parameters"
                                : "Show additional parameters"}
                        </Button>
                    )}

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {props.data.outputs.map((output, i) => {
                        const commonProps = {
                            className: "target",
                            "data-handleid": output.name,
                            "data-handlepos": "bottom",
                            "data-nodeid": props.id,
                        };

                        return (
                            <Handle
                                type="source"
                                position={Position.Bottom}
                                style={{
                                    backgroundColor: "#555",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    left:
                                        props.data.outputs.length > 1
                                            ? `${
                                                  (100.0 / (props.data.outputs.length - 1.0 / 3)) *
                                                  (i + 1.0 / 3)
                                              }%`
                                            : "50%",
                                }}
                                id={output.name}
                                key={output.name}
                                isConnectable={props.isConnectable}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, 10%)",
                                    }}>
                                    <Typography variant="body1" {...commonProps}>
                                        {output.name}
                                    </Typography>
                                    <Typography variant="caption" {...commonProps}>
                                        {`type=${
                                            props.data.batch
                                                ? output.data_type + "[]"
                                                : output.data_type
                                        }`}
                                    </Typography>
                                </div>
                            </Handle>
                        );
                    })}
                </div>
            </Box>
        </>
    );
};

function nodesToDag(nodes: Node[], edges: Edge[]): DagStepType[] {
    const edgeMap = new Map<string, Edge[]>();

    for (const edge of edges) {
        const edgeArray = edgeMap.get(edge.target) || [];
        edgeArray.push(edge);
        edgeMap.set(edge.target, edgeArray);
    }

    const pipeline: DagStepType[] = nodes.map((node) => {
        // Example value of a node here:
        /*  {
                "id":"dndnode_1",
                "type":"operator",
                "position":{"x":-172.7086717860799,"y":-447.56448254136876},
                "data":{
                    "description":"",
                    "inputs":[],
                    "name":"Ingest PDF",
                    "outputs":[{"data_type":"Document[]","name":"pdf_content"}],
                    "parameters":[{"data_type":"string","name":"pdf_uri","placeholder":"Enter the URL of the PDF"}],
                    "secrets":[],
                    "batch": false
                },
                "width":270,
                "height":63,
                "selected":true,
                "dragging":false,
                "positionAbsolute":{"x":-172.7086717860799,"y":-447.56448254136876}
            }
        */

        const inputs: Record<string, [string, string]> = {};

        const nodeEdges = edgeMap.get(node.id) || [];
        for (const edge of nodeEdges) {
            if (edge.targetHandle) {
                inputs[edge.targetHandle] = [edge.source, edge.sourceHandle ?? ""];
            }
        }

        // Transform parameters to an object
        const parameters: Record<string, string> = {};
        for (const parameter of node.data.parameters) {
            parameters[parameter.name] = parameter.value || null;
        }

        return {
            id: node.id,
            operator: node.data.name,
            position: node.position,
            width: node.width,
            height: node.height,
            selected: node.selected,
            parameters,
            inputs: Object.keys(inputs).length > 0 ? inputs : {},
            batch: node.data.batch,
        };
    });

    return pipeline;
}

export default OperatorNode;

export { nodesToDag };
export type { InputOutputType, OperatorNodeData, ParameterType };
