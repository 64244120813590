import React from "react";

import { ansiToReact } from "./Util";

interface ActionProps {
    content: string;
}

const Action: React.FC<ActionProps> = ({ content }) => {
    return <div className="mb-2 flex flex-col rounded-md p-2">{ansiToReact(content)}</div>;
};

export default Action;
