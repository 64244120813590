import { FC, useEffect, useRef, useState } from "react";

import FeedbackIcon from "@mui/icons-material/Feedback";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import SaveIcon from "@mui/icons-material/Save";
import FunctionsIcon from "@mui/icons-material/Functions";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { Link } from "react-router-dom";

import LogoIcon from "../assets/vectors/LogoIcon";
import app from "../auth/firebase";
import { googleSignIn } from "../auth/login";
import { LoginButton } from "../components/LoginBtn";
import { tokens } from "../theme";

interface SidebarProps {
    isSmallScreen: boolean;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlatformSidebar: FC<SidebarProps> = ({
    isSmallScreen,
    isCollapsed,
    setIsCollapsed,
    isHidden,
    setIsHidden,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Space");
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isMouseHovering, setIsMouseHovering] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const auth = getAuth(app);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        const handleDelayedCollapse = () => {
            if (!isCollapsed && !isMouseHovering) {
                setIsCollapsed(true);
            }
        };

        if (!isMouseHovering && !isCollapsed) {
            timer = setTimeout(handleDelayedCollapse, 0);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isMouseHovering, isCollapsed, setIsCollapsed]);

    // close the sidebar when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target as Node) &&
                !anchorEl
            )
                setIsCollapsed(true);
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsCollapsed, anchorEl]);

    const handleSignIn = async () => {
        if (!auth.currentUser) {
            await googleSignIn();
        }
    };

    const handleSignOut = () => {
        signOut(auth);
    };

    useEffect(() => {
        const refresh = onAuthStateChanged(auth, (user) => {
            // this is a listener that triggers every time the user's authentication state changes
            setCurrentUser(user);
        });

        return () => {
            refresh();
        };
    }, [auth]);

    const menuItems = [
        {
            title: "Functions",
            to: "/functions",
            icon: <FunctionsIcon />,
        },
        {
            title: "Function Editor",
            to: "/function_editor",
            icon: <DesignServicesIcon />,
        },
        {
            title: "Function Runs",
            to: "/run_history",
            icon: <WorkHistoryIcon />,
        },
        {
            title: "Space",
            to: "/space",
            icon: <TableViewOutlinedIcon />,
        },
        {
            title: "Secrets",
            to: "/secrets",
            icon: <KeyIcon />,
        },
        {
            title: "My teams",
            to: "/team",
            icon: <GroupsIcon />,
        },
        {
            title: "My files",
            to: "/data",
            icon: <SaveIcon />,
        },
        {
            title: "Help",
            to: "/feedback",
            icon: <FeedbackIcon />,
        },
    ];

    return (
        <div className="w-[60px]">
            <Drawer
                ref={sidebarRef}
                variant="permanent"
                onMouseEnter={() => {
                    setIsMouseHovering(true);
                    setIsCollapsed(false);
                }}
                onMouseLeave={() => setIsMouseHovering(false)}
                anchor="left"
                sx={{
                    width: isCollapsed ? 60 : 240,
                    flexShrink: 0,
                    position: "absolute",
                    transition: "width 0.3s ease",
                    "& .MuiDrawer-paper": {
                        width: isCollapsed ? 60 : 240,
                        boxSizing: "border-box",
                        backgroundColor: colors.primary[400],
                        transition: "width 0.3s ease",
                    },
                }}>
                <Box
                    display="flex"
                    alignItems="center"
                    p={1}
                    sx={{
                        backgroundColor: colors.primary[400],
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}>
                    <IconButton sx={{ mr: 1.5 }}>
                        <LogoIcon width={28} />
                    </IconButton>
                    <Typography variant="h3" color={colors.grey[100]}>
                        Spesh AI
                    </Typography>
                </Box>
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            disablePadding
                            key={item.to}
                            component={Link}
                            to={item.to}
                            onClick={() => setSelected(item.title)}
                            sx={{
                                display: "block",
                                "&.Mui-selected": {
                                    backgroundColor: colors.greenAccent[500],
                                },
                                "&.Mui-selected:hover": {
                                    backgroundColor: colors.greenAccent[600],
                                },
                                "&:hover": {
                                    backgroundColor: colors.greenAccent[500],
                                },
                            }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: "initial",
                                    px: 2.5,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}>
                                <ListItemIcon
                                    sx={{
                                        color: colors.grey[100],
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: "center",
                                    }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                    sx={{ color: colors.grey[100] }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <div className="flex-grow"></div>
                <Box display="flex">
                    <LoginButton
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        currentUser={currentUser}
                        handleSignOut={handleSignOut}
                        handleSignIn={handleSignIn}
                        collapsed={isCollapsed}
                    />
                </Box>
            </Drawer>
        </div>
    );
};

export default PlatformSidebar;
