import React, { useCallback, useEffect, useState, useRef } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Column, ColumnMovedEvent } from "ag-grid-community";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import toast from "react-hot-toast";

import app from "../auth/firebase";
import SpaceChat from "../components/SpaceChat";
import { fetch_with_auth } from "../components/Util";
import { Space } from "../types/Space";
import { UserProfile } from "../types/UserProfile";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

type RowDictionary = {
    [key: string]: number | string;
};

interface TableTabLabelProps {
    tableName: string;
    isSelected: boolean;
    onDownloadClick: () => void;
    onDeleteClick: () => void;
}

const TableTabLabel: React.FC<TableTabLabelProps> = ({
    tableName,
    isSelected,
    onDownloadClick,
    onDeleteClick,
}) => (
    <Box display="flex" alignItems="center">
        <Typography variant="body1">{tableName}</Typography>
        {isSelected && (
            <>
                <span
                    onClick={onDownloadClick}
                    style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        borderRadius: "4px",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0e0e0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>
                    <DownloadIcon />
                </span>
                <span
                    onClick={onDeleteClick}
                    style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        borderRadius: "4px",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0e0e0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>
                    <DeleteIcon color="error" />
                </span>
            </>
        )}
    </Box>
);

const SpacePage = () => {
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [tableName, setTableName] = useState<string>("");

    const [tableGridData, setTableGridData] = useState<{ [key: string]: any[] }>({});
    const [tableColumnDefs, setTableColumnDefs] = useState<{ [key: string]: any[] }>({});
    const [loadedTables, setLoadedTables] = useState<Set<string>>(new Set());

    const [selectedTable, setSelectedTable] = useState<string>("");
    const [tables, setTables] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [openDeleteTableDialog, setOpenDeleteTableDialog] = useState<boolean>(false);
    const [openAddTableDialog, setOpenAddTableDialog] = useState<boolean>(false);
    const [chatVisible, setChatVisible] = useState<boolean>(false);
    const [files, setFiles] = useState<string[]>([]);

    const gridRef = useRef<HTMLDivElement>(null);

    const [selectedSpaceId, setSelectedSpaceId] = useState<string>("");
    const [spaces, setSpaces] = useState<Space[]>([]);
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchFiles(userProfile.id_token);
            setSelectedFile("");
        }
    }, [userProfile.id_token]);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchSpaces(userProfile.id_token);
            const searchParams = new URLSearchParams(location.search);
            const spaceId = searchParams.get("space_id");
            if (spaceId && spaceId !== selectedSpaceId) {
                setSelectedSpaceId(spaceId);
            }
        }
    }, [userProfile.id_token]);

    useEffect(() => {
        renderSpace(selectedSpaceId);
    }, [spaces, selectedSpaceId]);

    // Ensure that default event listener is not going to trigger when right-clicking on the ag-grid spreadsheet,
    // this is needed to ensure that default chrome menu is not going to show up.
    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            event.preventDefault();
            //console.log("Custom context menu event triggered");
        };

        const gridContainer = gridRef.current;
        if (gridContainer) {
            //console.log("Grid container found, adding context menu event listener");
            gridContainer.addEventListener("contextmenu", handleContextMenu as EventListener);
        } else {
            console.log("Grid container not found, this should not happen");
        }

        return () => {
            if (gridContainer) {
                //console.log("Removing context menu event listener");
                gridContainer.removeEventListener(
                    "contextmenu",
                    handleContextMenu as EventListener,
                );
            }
        };
    }, [gridRef]);

    // Adding an event listener to hide the context menu when clicking outside the popup menu
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const contextMenu = document.getElementById("contextMenu");
            if (contextMenu && !contextMenu.contains(event.target)) {
                contextMenu.style.display = "none";
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const renderSpace = (spaceId: string) => {
        const currentSpace = spaces.find((space) => space.space_id === spaceId);
        if (currentSpace && userProfile.id_token) {
            //console.log(`renderSpace called, currentSpace = ${JSON.stringify(currentSpace)}`);
            setTables(Object.keys(currentSpace.tables));
            if (Object.keys(currentSpace.tables).length > 0) {
                const currentTable = Object.keys(currentSpace.tables)[0];
                setSelectedTable(currentTable);
                loadTableData(currentSpace.space_id, currentTable, userProfile.id_token);
            }
        }
    };

    const handleSpaceChange = async (event: SelectChangeEvent<string>) => {
        const spaceId = event.target.value as string;
        setSelectedSpaceId(spaceId);
        navigate(`/space?space_id=${spaceId}`);
        // renderSpace will be called as part of useEffect triggered by change in selectedSpaceid state variable.
        // renderSpace(spaceId);
    };

    const fetchSpaces = async (idToken: string) => {
        const newSpaces = await Space.get_spaces_for_user(idToken);
        setSpaces(newSpaces);
    };

    const updateSpace = async (space: Space) => {
        await fetch_with_auth("space", userProfile.id_token || "", "POST", space);
        setSpaces((prevSpaces) => {
            const newSpaces = prevSpaces.map((s) => (s.space_id === space.space_id ? space : s));
            return newSpaces;
        });
    };

    const fetchFiles = async (idToken: string) => {
        try {
            const response = await fetch_with_auth(`list_all_files`, idToken, "GET");
            setFiles(response.files);
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const handleCellContextMenu = useCallback((params: any, table: string) => {
        params.event.preventDefault();
        params.event.stopPropagation();

        if (params.node.data.idx !== "") {
            const contextMenu = document.getElementById("contextMenu");
            if (contextMenu) {
                contextMenu.style.top = `${params.event.clientY}px`;
                contextMenu.style.left = `${params.event.clientX}px`;
                contextMenu.style.display = "block";
                contextMenu.setAttribute("data-row-index", params.node.rowIndex);
                contextMenu.setAttribute("data-idx", params.node.data.idx);
                contextMenu.setAttribute("data-table", table);
            }
        }
    }, []);

    const handleAddRow = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            const rowIndex = parseInt(contextMenu.getAttribute("data-row-index") || "0", 10);
            const idx = parseInt(contextMenu.getAttribute("data-idx") || "0", 10);
            const newIdx = idx + 1;

            // Close the context menu
            contextMenu.style.display = "none";

            // Create a new empty row with only the idx field set
            const newRow: RowDictionary = { idx: newIdx };
            tableColumnDefs[selectedTable].forEach((colDef) => {
                if (colDef.field !== "idx") {
                    newRow[colDef.field as string] = "";
                }
            });

            // Update frontend state
            const updatedData = [...tableGridData[selectedTable]];
            updatedData.splice(rowIndex + 1, 0, newRow);
            for (let i = rowIndex + 2; i < updatedData.length; i++) {
                const currentIdx = parseInt(updatedData[i].idx);
                if (isNaN(currentIdx)) {
                    console.error(`Invalid idx value at row ${i}: ${updatedData[i].idx}`);
                } else {
                    updatedData[i].idx = currentIdx + 1;
                }
            }
            setTableGridData((prev) => ({ ...prev, [selectedTable]: updatedData }));

            if (selectedSpaceId) {
                await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                    action: "ADD",
                    idx: newIdx,
                    space_id: selectedSpaceId,
                    table_name: selectedTable,
                });
            }
        }
    };

    const handleAddColumn = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            // Close the context menu
            contextMenu.style.display = "none";

            // Add new column to the column definitions
            const newColumnDefs = [...tableColumnDefs[selectedTable]];
            newColumnDefs.push({
                headerName: "new_column_name",
                field: "new_column_name",
                editable: true,
            });
            setTableColumnDefs((prev) => ({ ...prev, [selectedTable]: newColumnDefs }));

            if (selectedSpaceId) {
                await fetch_with_auth("space_table", userProfile.id_token || "", "POST", {
                    space_id: selectedSpaceId,
                    table_name: selectedTable,
                    columns: newColumnDefs.map((col) => col.field),
                });
            }
        }
    };

    const handleDeleteRow = async () => {
        const contextMenu = document.getElementById("contextMenu");
        if (contextMenu) {
            const rowIndex = parseInt(contextMenu.getAttribute("data-row-index") || "0", 10);
            const idx = parseInt(contextMenu.getAttribute("data-idx") || "0", 10);

            // Close the context menu
            contextMenu.style.display = "none";

            // Update frontend state
            const updatedData = tableGridData[selectedTable].filter(
                (_, index) => index !== rowIndex,
            );
            for (let i = rowIndex; i < updatedData.length; i++) {
                updatedData[i].idx = parseInt(updatedData[i].idx) - 1;
            }
            setTableGridData((prev) => ({ ...prev, [selectedTable]: updatedData }));

            if (selectedSpaceId) {
                await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                    action: "DELETE",
                    idx: idx,
                    space_id: selectedSpaceId,
                    table_name: selectedTable,
                });
            }
        }
    };

    const loadTableData = useCallback(
        async (spaceId: string, tableName: string, idToken: string) => {
            const idxColumnDef = {
                headerName: "idx",
                field: "idx",
                pinned: "left",
                lockPosition: "left",
                editable: false,
                width: 35,
                sortable: true,
                cellStyle: { backgroundColor: "#f2f0f0" },
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation"><span class="ag-header-cell-label" role="presentation"><span class="ag-header-cell-text" role="columnheader">idx</span></span></div>',
                },
            };

            try {
                const response = await fetch_with_auth(
                    `space_table?space_id=${spaceId}&table_name=${tableName}`,
                    idToken,
                    "GET",
                );
                const space = spaces.find((space) => space.space_id === spaceId);
                if (!space) {
                    console.log(`This should not happen, couldnt find space with id = ${spaceId}`);
                } else {
                    let columnOrder = space.tables[tableName];

                    const idx_pos = columnOrder.indexOf("idx");
                    if (idx_pos > 0) {
                        columnOrder = [
                            columnOrder[idx_pos],
                            ...columnOrder.slice(0, idx_pos),
                            ...columnOrder.slice(idx_pos + 1),
                        ];
                    }

                    const newColumnDefs = columnOrder.map((column: string) =>
                        column === "idx"
                            ? idxColumnDef
                            : {
                                  headerName: column,
                                  field: column,
                                  editable: true,
                              },
                    );

                    const transformedData = response.data.rows.map((row: any[]) => {
                        const rowData: RowDictionary = {};
                        columnOrder.forEach((colName: string) => {
                            const dataPos = response.data.columns.indexOf(colName);
                            rowData[colName] = row[dataPos];
                        });
                        return rowData;
                    });

                    setTableColumnDefs((prev) => ({ ...prev, [tableName]: newColumnDefs }));
                    setTableGridData((prev) => ({ ...prev, [tableName]: transformedData }));
                    setLoadedTables((prev) => new Set(prev).add(tableName));
                }
            } catch (error: any) {
                toast.error(`Error fetching table ${tableName} data: ${error.message}`);
            }
        },
        [spaces],
    );

    const handleFileChange = (event: SelectChangeEvent<string>) => {
        setSelectedFile(event.target.value);
    };

    const handleTableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTableName(event.target.value);
    };

    const handlePopulateData = async () => {
        try {
            if (selectedSpaceId) {
                const body = {
                    file_name: selectedFile,
                    space_id: selectedSpaceId,
                    table_name: tableName,
                };
                await fetch_with_auth(
                    "space_spawn_table_from_file",
                    userProfile.id_token || "",
                    "POST",
                    body,
                );
                toast.dismiss();
                toast.success(`Populated table ${tableName} successfully`);
                // Make newly created table on top (selected)
                // First make sure that frontend is aware of most accurate set of tables in current space.
                fetchSpaces(userProfile.id_token || "");
                switchToTable(tableName);
                setOpenAddTableDialog(false);
            }
        } catch (error: any) {
            toast.error(`Error populating data: ${error.message}`);
        }
    };

    const switchToTable = (newTableName: string) => {
        if (!selectedSpaceId) {
            return;
        }
        setSelectedTable(newTableName);
        loadTableData(selectedSpaceId, newTableName, userProfile.id_token || "");
    };

    const handleTableChange = (event: React.ChangeEvent<any>, newValue: string) => {
        if (newValue !== "add_table") {
            setSelectedTable(newValue);
            if (!loadedTables.has(newValue) && selectedSpaceId) {
                loadTableData(selectedSpaceId, newValue, userProfile.id_token || "");
            }
        }
    };

    const handleRowSelection = (event: any, table: string) => {
        setSelectedRows(event.api.getSelectedRows());
    };

    const handleDownloadCSV = async () => {
        if (!selectedSpaceId || !selectedTable) {
            toast.error("No table selected for download.");
            return;
        }

        const url = `space_export_table?space_id=${selectedSpaceId}&table_name=${selectedTable}`;
        const result_blob = await fetch_with_auth(
            url,
            userProfile.id_token || "",
            "GET",
            null /* body */,
            true /* return_blob */,
        );

        const downloadUrl = window.URL.createObjectURL(result_blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadUrl;
        a.download = `${selectedTable}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
        a.remove();
    };

    const handleDeleteClick = () => {
        setOpenDeleteTableDialog(true);
    };

    const handleCloseDeleteTableDialog = () => {
        setOpenDeleteTableDialog(false);
    };

    const handleOpenAddTableDialog = () => {
        setOpenAddTableDialog(true);
    };

    const handleCloseAddTableDialog = () => {
        setOpenAddTableDialog(false);
    };

    const handleConfirmDelete = async () => {
        try {
            if (selectedSpaceId) {
                const url = `space_table?space_id=${selectedSpaceId}&table_name=${selectedTable}`;
                await fetch_with_auth(url, userProfile.id_token || "", "DELETE");

                toast.success(`Table ${selectedTable} deleted successfully.`);
                setOpenDeleteTableDialog(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1000); // 1000 milliseconds = 1 second
            }
        } catch (error: any) {
            toast.error(`Error deleting table ${selectedTable}: ${error.message}`);
        }
    };

    const toggleChatVisibility = () => {
        setChatVisible((prev) => !prev);
        // Ensure the page scrolls to the top
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const onColumnMoved = async (event: ColumnMovedEvent, table: string) => {
        if (event.finished && event.column) {
            // The desired behavior for this method to do something only when user manually dragged and dropped
            // column in new spot. To ensure it we are checking for:
            // (1) event.finished for being True so that to not act upon incomplete column movement where
            // user did not stop dragging just yet. I.e. they could start dragging and already change the position of the
            // column but did not release mouse-1 yet.
            // (2) event.column being set to True-y value which is only the case when single column is moved.
            // When switching between tables it is re-creating all the columns thus triggering onColumnMoved callback.

            // Note that "idx" column is special and should not be movable.
            if (event.column.getColId() === "idx") {
                console.log(
                    "This should not be happening as idx column should always be pinned in the first place",
                );
                event.api.setColumnsPinned(["idx"], "left");
                return;
            }

            const newColumnOrder = event.api
                .getAllGridColumns()
                .map((col: Column) => col.getColId());
            const updatedSpaces = spaces.map((space) => {
                if (space.space_id === selectedSpaceId) {
                    return {
                        ...space,
                        tables: {
                            ...space.tables,
                            [table]: newColumnOrder,
                        },
                    } as Space;
                }
                return space;
            });

            const updatedSpace = updatedSpaces.find((space) => space.space_id === selectedSpaceId);
            if (!updatedSpace) {
                console.error("Could not find current space in updated spaces.");
                return;
            }
            try {
                await updateSpace(updatedSpace);
                toast.success(`Column order for table ${table} saved successfully.`);
            } catch (error) {
                toast.error(`Failed to save column order for table ${table}.`);
                return;
            }
        }
    };

    const handleCellValueChanged = useCallback(
        async (params: any, table: string) => {
            const updatedRow = params.data;
            const updatedRowJson = JSON.stringify(updatedRow);
            const idx = updatedRow.idx;

            await fetch_with_auth("space_table_row", userProfile.id_token || "", "POST", {
                action: "EDIT",
                idx: idx,
                row: updatedRowJson,
                space_id: selectedSpaceId,
                table_name: table,
            });
        },
        [userProfile.id_token, selectedSpaceId],
    );

    return (
        <>
            <Box display="flex" alignItems="center" marginBottom="20px">
                <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "0", marginRight: "20px" }}>
                    <InputLabel id="space-select-label">Select Space</InputLabel>
                    <Select
                        labelId="space-select-label"
                        id="space-select"
                        value={selectedSpaceId}
                        onChange={handleSpaceChange}
                        label="Select Space">
                        {spaces.map((space) => (
                            <MenuItem key={space.space_id} value={space.space_id}>
                                {`${space.name} (id: ${space.space_id})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button onClick={toggleChatVisibility} variant="contained" color="primary">
                    {chatVisible ? "Hide Chat" : "Show Chat"}
                </Button>
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
                <div className="flex">
                    <Tabs value={selectedTable || "add_table"} onChange={handleTableChange}>
                        {tables.map((table) => (
                            <Tab
                                key={table}
                                value={table}
                                label={
                                    <TableTabLabel
                                        tableName={table}
                                        isSelected={selectedTable === table}
                                        onDownloadClick={handleDownloadCSV}
                                        onDeleteClick={handleDeleteClick}
                                    />
                                }
                                sx={{
                                    "&:hover": {
                                        backgroundColor:
                                            selectedTable === table ? "#d0d0d0" : "#f0f0f0",
                                    },
                                    backgroundColor:
                                        selectedTable === table ? "#d0d0d0" : "transparent",
                                }}
                            />
                        ))}
                        <Tab
                            icon={<AddCircleOutlineIcon />}
                            label="Add Table"
                            value="add_table"
                            onClick={handleOpenAddTableDialog}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        />
                    </Tabs>
                    <div className="flex-1" />
                </div>
                <div className="ag-theme-alpine flex flex-grow space-x-4" ref={gridRef}>
                    <div className="ag-grid-container flex-1" ref={gridRef}>
                        {tables.map((table) => (
                            <div
                                key={table}
                                style={{
                                    display: selectedTable === table ? "block" : "none",
                                    width: "100%",
                                    overflow: "hidden",
                                }}>
                                <AgGridReact
                                    rowData={tableGridData[table] || []}
                                    columnDefs={tableColumnDefs[table] || []}
                                    pagination={true}
                                    paginationPageSize={100}
                                    rowSelection="multiple"
                                    onSelectionChanged={(event) => handleRowSelection(event, table)}
                                    onColumnMoved={(event) => onColumnMoved(event, table)}
                                    onCellContextMenu={(event) =>
                                        handleCellContextMenu(event, table)
                                    }
                                    onCellValueChanged={(event) =>
                                        handleCellValueChanged(event, table)
                                    }
                                    domLayout="autoHeight"
                                />
                            </div>
                        ))}
                    </div>

                    {chatVisible && (
                        <div className="h-full">
                            <SpaceChat
                                selectedSpaceId={selectedSpaceId}
                                userProfile={userProfile}
                                selectedRows={selectedRows}
                                selectedTable={selectedTable}
                            />
                        </div>
                    )}
                </div>
            </Box>
            <Dialog
                open={openDeleteTableDialog}
                onClose={handleCloseDeleteTableDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Delete Table"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {selectedTable}? This operation is not
                        possible to reverse.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteTableDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAddTableDialog}
                onClose={handleCloseAddTableDialog}
                aria-labelledby="add-table-dialog-title"
                aria-describedby="add-table-dialog-description">
                <DialogTitle id="add-table-dialog-title">{"Add Table from CSV"}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
                        <InputLabel id="file-select-label">Select CSV File</InputLabel>
                        <Select
                            labelId="file-select-label"
                            id="file-select"
                            value={selectedFile}
                            onChange={handleFileChange}
                            label="Select CSV File">
                            {files.map((file, index) => (
                                <MenuItem key={index} value={file}>
                                    {file}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Table Name"
                        value={tableName}
                        onChange={handleTableNameChange}
                        style={{ marginBottom: "20px" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddTableDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePopulateData} color="primary" autoFocus>
                        Add Table
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                id="contextMenu"
                style={{
                    display: "none",
                    position: "absolute",
                    backgroundColor: "white",
                    border: "1px solid black",
                    zIndex: 1000,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}>
                <ul style={{ listStyleType: "none", margin: 0, padding: "10px" }}>
                    <li className="contextMenuItem" onClick={handleAddRow}>
                        Add new row after this one
                    </li>
                    {false && (
                        <li className="contextMenuItem" onClick={handleAddColumn}>
                            Add new column after this one
                        </li>
                    )}
                    <li className="contextMenuItem" onClick={handleDeleteRow}>
                        Delete this row
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SpacePage;
